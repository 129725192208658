<template>
</template>
<script>
import store from "@/plugins/store";

export default{
  data(){
    return {
      equipmentId: null
    }
  },
  methods:{
    jumpTo(path, query) {
      this.$router.push({path: path, query: query || {}})
    },
    onDecode(equipmentId) {
      const that = this;
      this.$http.post(`/api/booking/getCurrentBooking?equipmentId=${equipmentId}`).then(res => {
        if (res.success) {
          //判断返回的是预约还是仪器
          let data = res.data
          console.log("data",data)
          if(data.isNoBooking){
            console.log("isNoBooking")
            store.commit('setCurBooking', data.noBooking)
            that.$router.push({
              path: '/confirm',
              query: {
                equipmentId: equipmentId,
                isNoBooking: true,

              }

            });
          }else {
            let equipmentId=data.equipmentId
            console.log("booking",data.id)

            if (data.clientOpen){
              that.$router.push({
                path: '/exp',
                query: {
                  equipmentId: equipmentId,
                  bookingId: data.id,

                }
              })
            } else {
              store.commit('setCurBooking', data)
              that.$router.push({
                path: '/confirm',
                query: {
                  equipmentId: equipmentId,
                  isNoBooking: false
                }

              });
            }
          }


        } else {
          that.show = false
          that.prepared = false
          setTimeout(function (){
            that.show = true
          }, 500)
        }
      });
    },
  },
  mounted() {
    this.equipmentId= this.$route.query.equipmentId
    this.onDecode(this.equipmentId)
  }
}
</script>
